@import "~@fortawesome/fontawesome-free/scss/fontawesome";

$main-green: #50b948;
$light-grey-bg: #e0e0e08c;
$shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

@font-face {
	font-family: "Maven Pro";
	font-weight: 400;
	src: url("../client/assets/MavenPro-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "Maven Pro";
	font-weight: 600;
	src: url("../client/assets/MavenPro-SemiBold.ttf") format("truetype");
}
$font-main: "Maven Pro";

@mixin wide {
	max-width: 1600px;
	@media (min-width: 1610px) {
		margin: 0 auto;
	}
}
.fas {
	font-family: "FontAwesome" !important;
}
body {
	width: 100%;
	font-family: $font-main;
	line-height: 2vh;
	font-size: 1.3vh;
}

#root {
	width: 100%;
	.approot {
		width: 100%;
		height: 100%;
		@media (max-width: 960px) {
			display: flex;
			flex-direction: column;
		}
		#navigation {
			width: 100%;
			height: 10vh;
			background: white;
			white-space:nowrap;
			.navigation-main {
				height: 100%;
				padding: 0 8vw;
				@media (max-width: 960px) {
					padding: 0 2vw;
				}
				display: flex;
				justify-content: space-between;
				align-items: center;
				@media print {
					display: none;
				}
				&-logo {
					height: 50%;
					cursor: pointer;
					> a > img {
						height: 100%;
					}
				}
				&-menu {
					color: $main-green;
					width: 40vw;
					display: flex;
					justify-content: space-between;
					@media (max-width: 960px) {
						display: none;
					}
					&-item {
						cursor: pointer;
						font-size: 1.1rem;
						font-weight: 700;
						text-align: center;
						padding: 5px 10px;
						word-break: keep-all;
					}
				}
				&-menu--mobile {
					display: none;
					color: #1f9122;
					background: white;
					position: absolute;
					top: 10vh;
					left: 2vw;
					right: 2vw;
					z-index: 1;
					@media (max-width: 960px) {
						display: block;
					}
					&-wrap {
						border: 1px solid black;
						&-item {
							cursor: pointer;
							padding: 0.8em 1em 0.8em;
						}
					}
				}
				&-menu--icon {
					display: none;
					width: 29px;
					height: 29px;
					flex-direction: column;
					justify-content: space-evenly;
					align-items: center;
					@media (max-width: 960px) {
						display: flex;
					}
					> div {
						height: 4px;
						width: 85%;
						background: black;
						border-radius: 2px;
					}
				}
			}
		}
		.footer {
			height: 5vh;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			@media (max-width: 960px) {
				display: block;
				height: unset;
			}
			@media print {
				display: none;
			}
			> div {
				display: flex;
				@media (max-width: 960px) {
					display: block;
					text-align: center;
				}
				> div {
					padding: 0 1em;
				}
			}
		}
		#mainview {
			width: 100%;
			height: 85vh;
			padding: 0vh 8vw;
			background: $light-grey-bg;
			position: relative;
			@media (max-height: 960px) {
			}
			@media (max-width: 960px) {
				margin: 0;
				padding: 0;
				min-height: unset;
				height: unset;
			}
			.landingpage {
				width: 100%;
				height: 100%;
				@include wide();
				&-wrap {
					display: flex;
					flex-wrap: wrap;
					height: 100%;
					width: 100%;
					justify-content: space-between;
					align-items: center;
					padding: 0.5em 0;
					@media (max-width: 960px) {
						display: block;
						padding: 0;
					}
					.secondary-links {
						width: 32%;
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						@media (max-width: 960px) {
							width: 100%;
							height: unset;
						}
						.applink {
							cursor: pointer;
							width: 100%;
							height: 28%;
							position: relative;
							overflow: hidden;
							@media (max-width: 960px) {
								width: 100%;
								height: 24vh;
							}
							.header {
								background-color: white;
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								padding-left: 1rem;
								height: 40px;
								font-size: 20px;
								border-bottom: 2px solid $main-green;
								display: flex;
								align-items: center;
							}
							.bgimg {
								height: 100%;
								width: 100%;
								background-position: center;
								background-repeat: no-repeat;
								background-size: cover;
								overflow: hidden;
								transition: transform 0.5s ease;
							}
							.bgimg:hover {
								transform: scale(1.2);
							}
							.description {
								position: absolute;
								bottom: 0;
								height: 20%;
								width: 100%;
								background: #303030aa;
								color: white;
								padding: 0.5em 0 0 0.5em;
							}
						}
						.doclink {
							cursor: pointer;
							width: 100%;
							height: 20%;
							position: relative;
							overflow: hidden;
							@media (max-width: 960px) {
								width: 100%;
								height: 24vh;
							}
							.header {
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								background-color: white;
								padding-left: 1rem;
								height: 40px;
								font-size: 20px;
								border-bottom: 2px solid $main-green;
								display: flex;
								align-items: center;
							}
							.bgimg {
								height: 100%;
								width: 100%;
								background-position: center;
								background-repeat: no-repeat;
								background-size: cover;
								overflow: hidden;
								transition: transform 0.5s ease;
							}
							.bgimg:hover {
								transform: scale(1.2);
							}
							.description {
								position: absolute;
								bottom: 0;
								height: 26%;
								width: 100%;
								background: #303030aa;
								color: white;
								padding: 0.5em 0 0 0.5em;

							}
						}
					}
					> .applink {
						cursor: pointer;
						background-color: white;
						&.big {
							width: 66%;
							height: 100%;
							position: relative;
							overflow: hidden;
							@media (max-width: 960px) {
								width: 100%;
								height: 60vh;
							}
							.header {
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								background: white;
								padding-left: 1rem;
								height: 60px;
								font-size: 32px;
								border-bottom: 3px solid $main-green;
								display: flex;
								align-items: center;
								@media (max-width: 960px) {
									font-size: 20px;
								}
							}
							> .imagewrap {
								height: 100%;
								width: 100%;
								overflow: hidden;
								transition: transform 0.5s ease;
								background-image: url("../client/assets/valmetSP.jpg");
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
								@media (max-width: 960px) {
									height: 60vh;
								}
							}
							> .imagewrap:hover {
								transform: scale(1.2);
							}
							.description {
								position: absolute;
								bottom: 0;
								height: 20%;
								width: 100%;
								background: #303030aa;
								color: white;
								padding: 1em 0em 0 1em;
								font-size: 3.5vh;
								@media (max-width: 960px) {
									height: 24%;
								}
							}
						}
					}
				}
			}
			.helppage {
				width: 100%;
				height: 100%;
				padding: 0.4em;
				@media (max-width: 960px) {
					height: unset;
				}
				@include wide();
				&-wrap {
					overflow: auto;
					background: white;
					display: flex;
					flex-direction: column;
					height: 100%;
					width: 100%;
					padding: 0.5em;
					&-spacer {
						margin: 1em;
					}
					@media (max-width: 960px) {
						height: unset;
					}
					> ul li {
						list-style: circle outside none;
						margin-top: 10px;
					}
				}
			}
			.generaldataform {
				height: 100%;
				padding: 0.5em 0;
				@include wide();
				.calculationspecs {
					background: white;
					padding: 0 2em;
					width: 40%;
					margin: auto;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					@media (max-width: 960px) {
						width: 100%;
						height: unset;
					}
					&--header {
						width: 100%;
					}
					&--form {
						width: 100%;
						> div {
							> div {
								margin: 0.5em 0;
							}
							> input {
								width: 100%;
							}
							> textarea {
								width: 100%;
								resize: none;
							}
						}
					}
					&--submit {
						@media (max-width: 960px) {
							padding-top: 1em;
						}
						> button {
							cursor: pointer;
							background: white;
							border: 1px solid $main-green;
							color: $main-green;
							width: 100%;
							outline: none;
							height: 4em;
							font-weight: 700;
						}
					}
				}
			}
			.consistencypage {
				width: 100%;
				height: 100%;
				padding-bottom: 0.5em;
				@media (max-width: 960px) {
					height: unset;
					padding: unset;
				}
				@include wide();
				&--top {
					display: flex;
					height: 4vh;
					@media (max-width: 960px) {
						height: 5vh;
					}
					@media print {
						display: none;
					}
					&--steps {
						width: 38em;
						display: flex;
						align-items: center;
						justify-content: space-between;
						@media (max-width: 960px) {
							display: none;
							width: 70%;
						}
						&--item {
							cursor: pointer;
							width: 30%;
							height: 60%;
							border: 1px solid $main-green;
							color: $main-green;
							display: flex;
							align-items: center;
							justify-content: center;
							&.active {
								color: white;
								background: $main-green;
							}
						}
					}
					&--mobile {
						flex: 1;
						display: none;
						align-items: center;
						justify-content: center;
						@media (max-width: 960px) {
							display: flex;
						}
						&--item {
							margin: 0 0.3em;
							background: rgb(185, 185, 185);
							height: 10px;
							width: 10px;
							border-radius: 50%;
							&.active {
								background: $main-green;
							}
						}
					}
					&--right {
						flex: 1;
						display: flex;
						align-items: center;
						text-align: right;
						&--header {
							flex: 1;
							height: 100%;
							align-items: center;
							justify-content: flex-end;
							display: flex;
							color: $main-green;
							> span {
								cursor: pointer;
							}
						}
					}
				}
				&--generalform {
					position: absolute;
					left: 0;
					bottom: 0;
					top: 0;
					background: rgba(189, 189, 189, 0.5);
					width: 100%;
    				padding: 3vh 8vw;
					z-index: 1;
				}
			}
			.materialspage {
				width: 100%;
				height: 100%;
				padding: 1em 0;
				@media (max-width: 960px) {
					height: unset;
					padding: unset;
				}
				@include wide();
			}
			.tutorialspage {
				width: 100%;
				height: 100%;
				padding: 1em 0;
				@media (max-width: 960px) {
					height: unset;
					padding: unset;
				}
				@include wide();
			}
		}
	}
}

#ccalculation {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: calc(100% - 4vh);
	@media (max-width: 960px) {
		margin-bottom: 1em;
		height: unset;
	}
	.graph {
		width: 60%;
		height: 60vh;
		background: white;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
		@media (max-width: 960px) {
			width: 100%;
			height: 42vh;
			margin-bottom: 0.5em;
		}
		&--title {
			display: flex;
			align-items: center;
			height: 6%;
			font-weight: 700;
			padding-left: 1em;
			padding-top: 0.5em;
		}
		&--area {
			height: 90%;
			width: 100%;
			&--error {
				color: red;
				text-align: center;
			}
		}
	}
	.wrapper {
		width: 35%;
		@media (max-width: 960px) {
			width: 100%;
			height: 42vh;
			overflow-y: scroll;
		}
	}
	.options {
		width: 100%;
		padding: 0em 1em;
		background: white;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		height: 100%;
		input {
			height: 4vh;
		}
		select {
			height: 4vh;
		}
		@media (max-height: 900px) {
			max-height: 600px;
		}
		@media (max-width: 960px) {
			width: 100%;
			height: unset;
			padding: 0.5em 1em 1em;
		}
		&--title {
			font-weight: 700;
			display: flex;
			flex-wrap: wrap;
			@media (max-width: 960px) {
				padding-bottom: 0.5em;
			}
			.text:first-child {
				flex: 2;
			}
			.text:last-child {
				flex: 3;
			}
		}
		&--pipe {
			@media (max-width: 960px) {
				padding-bottom: 0.5em;
			}
			&--material {
				@media (max-width: 960px) {
					padding-bottom: 0.5em;
				}
				&--title {
					font-size: 1.1em;
					@media (max-width: 960px) {
						padding-bottom: 0.5em;
					}
				}
				> section {
					display: flex;
					align-items: center;
				}
			}
			&--diameter {
				@media (max-width: 960px) {
					padding-bottom: 0.5em;
				}
				&--title {
					font-size: 1.1em;
				}
				&--wrap {
					&--range {
						padding: 0.3em 0;
						> input.range {
							width: 100%;
							-webkit-appearance: none;
							-moz-appearance: none;
							background: lightgrey;
							height: 6px;
							appearance: none;
						}
						> input.range::-webkit-slider-thumb {
							-webkit-appearance: none;
							appearance: none;
							height: 15px;
							width: 15px;
							border-radius: 50%;
							background: $main-green;
						}
						> input.range::-moz-range-thumb {
							appearance: none;
							height: 15px;
							width: 15px;
							border-radius: 50%;
							background: $main-green;
						}
					}
					> section {
						display: flex;
						align-items: center;
					}
				}
			}
		}
		&--set {
			&--title {
				font-size: 1.1em;
				@media (max-width: 960px) {
					padding-bottom: 0.5em;
				}
			}
			&--wrap {
				> select {
					width: 100%;
				}
				> section {
					display: flex;
					align-items: center;
				}
			}
			@media (max-width: 960px) {
				padding-bottom: 0.5em;
			}
		}
		&--sensor {
			&--type {
				@media (max-width: 960px) {
					padding-bottom: 0.5em;
				}
				&--title {
					font-size: 1.1em;
					@media (max-width: 960px) {
						padding-bottom: 0.5em;
					}
				}
				&--wrap {
					display: flex;
					&--item {
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 2rem;
						height: 2rem;
						margin: 0 0.4rem;
						box-shadow: $shadow;
						background: $main-green;
						color: white;

						font-weight: 700;
						border: none;
					}
					> div:first-of-type {
						margin-left: 0;
					}
					&--item.active {
						background: #2f6c2a;
					}
					&--item:focus {
						border: 1px outset black;
					}
				}
			}
		}
		&--values {
			padding-bottom: 0.2em;
			&--flow {
				@media (max-width: 960px) {
					padding-bottom: 0.5em;
				}
				&--title {
					font-size: 1.1em;
					@media (max-width: 960px) {
						padding-bottom: 0.5em;
					}
				}
				&--fields {
					display: flex;
					justify-content: space-between;
					> input {
						width: 30%;
					}
					> select {
						width: 30%;
					}
				}
			}
			&--velocity {
				@media (max-width: 960px) {
					padding-bottom: 0.5em;
				}
				&--title {
					font-size: 1.1em;
					@media (max-width: 960px) {
						padding-bottom: 0.5em;
					}
				}
				&--fields {
					display: flex;
					justify-content: space-between;
					> input {
						width: 30%;
					}
					> select {
						width: 30%;
					}
				}
			}
			&--consistency {
				&--title {
					font-size: 1.1em;
					@media (max-width: 960px) {
						padding-bottom: 0.5em;
					}
				}
				&--fields {
					display: flex;
					justify-content: space-between;
					> input {
						width: 30%;
					}
					> div {
						width: 30%;
					}
				}
			}
		}
		&--button {
			display: flex;
			justify-content: flex-end;
			> button {
				cursor: pointer;
				outline: none;
				border: 1px solid $main-green;
				color: $main-green;
				background: none;
			}
			> button:focus {
				border: 1px outset black;
			}
		}
	}
}

#crequirements {
	display: flex;
	height: calc(100% - 4vh);
	@media (max-width: 960px) {
		height: unset;
		overflow-y: scroll;
	}
	.wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		@media (max-width: 960px) {
			height: unset;
		}
		.positionview {
			width: 60%;
			height: 52%;
			background: white;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
			padding: 0 0.8em 0.8em 0.8em;
			@media (max-width: 960px) {
				height: 50vh;
				width: 100%;
			}
			&--title {
				display: flex;
				align-items: center;
				height: 4vh;
				font-size: 1.1em;
			}
			&--area {
				image-rendering: -webkit-optimize-contrast;
				height: calc(100% - 4vh);
				width: 100%;
				display: flex;
				justify-content: center;
				> div {
					height: 100%;
					width: 80%;
				}
			}
			@media (max-width: 960px) {
				width: 100%;
			}
		}
		.orientation {
			width: 38%;
			height: 52%;
			background: white;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
			padding: 0.2em 0.5em;
			@media (max-width: 960px) {
				height: unset;
				width: 100%;
			}
			&--title {
				display: flex;
				align-items: center;
				height: 4vh;
				font-size: 1.1em;
				@media (max-width: 960px) {
					margin-bottom: 0.5em;
				}
			}
			&--list {
				> select {

					width: 100%;
					height: 4vh;
					border: 1px solid lightgrey;
				}
				margin-bottom: 0.5em;
			}
			&--text {
				border: 1px solid lightgrey;
				height: 37%;
				margin-bottom: 0.2em;
				white-space: normal;
				@media (max-width: 960px) {
					margin-bottom: 0.5em;
				}

				padding: 0.5em;
				overflow-y: scroll;
				@media (max-width: 960px) {
					height: 20vh;
					width: 100%;
				}
			}
			&--requirements {
				border: 1px solid lightgrey;

				padding: 0.5em;
				.row {
					height: 2vh;
					display: flex;
					&--header {
						font-weight: 700;
						margin-right: 4px;
					}
				}
			}
		}
		.mounting {
			width: 100%;
			height: 45%;
			margin-top: 0.5em;
			padding: 0.2em 0.4em;
			background: white;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
			display: flex;
			flex-direction: column;
			@media (max-width: 960px) {
				height: 110vh;
				margin-bottom: 1em;
			}
			&--title {
				display: flex;
				align-items: center;
				height: 4vh;
				padding-left: 0.2em;
				font-size: 1.1em;
				font-weight: 600;
			}
			&--subtitle {
				padding-left: 0.2em;
				height: 4vh;
			}
			&--flex {
				display: flex;
				justify-content: space-between;
				height: calc(100% - 75px);
				@media (max-width: 960px) {
					flex-wrap: wrap;
				}
				.item {
					width: 15vw;
					height: 100%;
					@media (max-width: 960px) {
						width: 48%;
						height: 30vh;
					}
					&--image {
						height: 62%;
						width: 100%;
						margin-bottom: 0.2em;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						&--radio {
							display: flex;
							justify-content: flex-end;
							> input {
								@media (max-width: 960px) {
									height: 4vh;
									width: 4vh;
								}
							}
						}
					}
					&--values {
						border: 1px solid lightgrey;

						padding: 0.5em;
						.row {
							height: 3.5vh;
							display: flex;
							&--header {
								font-weight: 700;
							}
						}
					}
				}
			}
			&--submit {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				height: 4vh;
				> button {
					width: 22%;
					cursor: pointer;
					outline: none;
					border: 1px solid $main-green;
					color: $main-green;
					background: none;
					@media (max-width: 960px) {
						width: 40%;
					}
				}
				> button:focus {
					border: 1px outset black;
				}
			}
		}
	}
}

#summarypage {
	background: white;
	padding: 0.5em 1em;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

	height: calc(100% - 4vh);
	position: relative;
	@media (max-width: 960px) {
		margin-bottom: 1em;
	}
	@media print {
		margin: 0;
		height: 100vh;
		padding: 1em;
		font-size: 1.3vmin;
	}
	.watermark {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&--image {
			display: none;
			height: 15%;
			opacity: 0.15;
			background-image: url("../client/assets/logo.png");
			background-position: center;
			background-size: contain;
			background-repeat: repeat-x;
			&.offset {
				background-position-x: left;
			}
			@media print {
				width: 100%;
				display: block;
			}
		}
	}
	> .logo {
		display: none;
		@media print {
			display: block;
			position: relative;
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
		> img {
			height: 50px;
		}
	}
	> .printfooter {
		display: none;
		@media print {
			position: relative;
			width: 100%;
			height: 3.5vh;
			display: flex;
			justify-content: flex-end;
		}
		&--text {
			width: 200px;
		}
	}
	> .header {
		position: relative;
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.1em;
		font-weight: 600;
		height: 3.5vh;
		@media (max-width: 960px) {
			width: 100%;
			height: auto;
		}
	}
	.canvaswrap {
		position: relative;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		height: 54%;
		@media print {
			height: auto;
		}
		.flexwrap {
			width: 35%;
			display: flex;
			flex-wrap: wrap;
			@media (max-width: 960px) {
				height: unset;
				width: 100%;
			}
			@media print {
				width: 35%;
				height: 40vh;
			}
			.header {
				font-size: 1.1em;
				font-weight: 600;
				height: 3.5vh;
				@media print {
					height: 1vh;
				}
			}
			.general {
				background: rgba(255, 255, 255, 0.7);
				width: 100%;
				border: 1px solid lightgrey;
				padding: 0.2em 0.5em;
				max-height: 45%;
				@media (max-width: 960px) {
					width: 100%;
					height: unset;
				}
				&--notes {
					&--header {
						font-weight: 600;
						display: inline;
						margin-right: 0.2em;
					}
					&--text {
						display: inline;
					}
					min-height: 54px;
					overflow: hidden;
				}
				.item {
					display: flex;
					height: 2.2vh;
					&--header {
						font-weight: 600;
						margin-right: 0.2em;
					}
				}
			}
			.calculation {
				background: rgba(255, 255, 255, 0.7);
				width: 100%;
				border: 1px solid lightgrey;
				padding: 0.2em 0.5em;
				max-height: 45%;
				@media (max-width: 960px) {
					width: 100%;
				}
				.item {
					display: flex;
					height: 2.2vh;
					&--header {
						font-weight: 600;
						margin-right: 0.2em;
					}
				}
			}
		}
		.canvas {
			width: 45%;
			height: 100%;
			@media (max-width: 960px) {
				height: 30vh;
				width: 100%;
			}
			@media print {
				width: 45%;
				height: 40%;
			}
		}
	}

	.mounting {
		position: relative;
		margin-top: 0.5em;
		height: 36%;
		@media (max-width: 960px) {
			height: unset;
		}
		@media print {
			height: 30%;
		}
		.flex {
			display: flex;
			flex-wrap: wrap;
			height: 100%;
			@media (max-width: 960px) {
				justify-content: center;
				height: unset;
			}
			@media print {
				height: 100%;
				justify-content: initial;
				flex-wrap: nowrap;

			}
			.item {
				width: 19%;
				margin: 0 5px;
				display: flex;
				flex-direction: column;
				@media (max-width: 960px) {
					height: 40%;
					width: 80%;
					margin: 0;
				}
				@media print {
					height: 100%;
					width: 19%;
					margin: 0 5px;
				}

				&--image {
					height: 50%;
					width: 100%;
					@media print {
						height: 180px;
					}
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					&--radio {
						display: flex;
						justify-content: flex-end;
					}
				}
				&--values {
					background: rgba(255, 255, 255, 0.7);
					border: 1px solid lightgrey;
					padding: 0.2em 0.5em;
					.row {
						height: 2.2vh;
						display: flex;
						white-space: nowrap;
						&--header {
							font-weight: 600;
						}
					}
					&--notes {
						@media (max-width: 960px) {
							height: unset;
						}
						&--title {
							font-weight: 600;
						}
						&--area {
							> textarea {
								resize: none;
								width: 100%;
							}
							@media print {
								::-webkit-input-placeholder {
									color: transparent;
								}
								::-moz-placeholder {
									color: transparent;
								}
								:-ms-input-placeholder {
									color: transparent;
								}
							}
						}
					}
				}
			}
			.item:first-child {
				margin-left: 0;
			}
			.item:last-child {
				margin-right: 0;
			}
		}
	}
	.download {
		position: relative;
		margin: 0.2em 0;
		@media print {
			display: none;
		}
		display: flex;
		justify-content: flex-end;
		align-items: center;
		> button {
			width: 22%;
			cursor: pointer;
			outline: none;
			border: 1px solid $main-green;
			color: $main-green;
			background: none;
			@media (max-width: 960px) {
				width: 40%;
			}
		}
		> button:focus {
			border: 1px outset black;
		}
	}
}
@mixin rowspecs {
	.row {
		&--headers {
			display: flex;
			&--main {
				flex: 2;
			}
			&--secondary {
				flex: 1;
				display: flex;
				margin-left: 1em;
				&--filler {
					flex: 1;
				}
				&--header {
					flex: 1;
					margin-left: 1em;
				}
			}
		}
		&--wrap {
			display: flex;
			justify-content: space-between;
			&--value {
				flex: 2;
				> input {
					height: 3vh;
					width: 100%;
				}
			}
			&--unit {
				flex: 1;
				margin-left: 1em;
				display: flex;
				> select {
					height: 3vh;
					flex: 1;
				}
				> div.select {
					flex: 1;
					> select {
						height: 3vh;
					}
				}
				> div.input {
					margin-left: 0.5em;
					flex: 1;
					> input {
						height: 3vh;
						width: 100%;
					}
				}
			}
		}
	}
}
.converterpage {
	.unitconverterPage {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 75%;
	}
	.swapbuttoncontainer {
		display: flex;
		flex-direction: column;
		justify-content: start;
	}
	.swapButton {
		width: 30px;
		height: 30px;
		margin-top: calc(6px + 1.2em);
		margin-left: 30px;
		margin-right: 30px;
		border: 1px;
		text-decoration: none;
		display: inline-block;
	}
	.equalicon {
		width: 30px;
		height: 30px;
		margin-top: calc(6px + 1.2em);
		margin-left: 30px;
		margin-right: 30px;
		border: 1px;
		text-decoration: none;
		text-align: center;
		display: inline-block;
	}
	.unitconverterfield {
		display: flex;
		width: 75%;
		margin-bottom: 20px;
		justify-content: center;
		height: 30px;
	}

	.convertInput {
		display: flex;
		flex: 1;
		flex-direction: column;
	}
	.convertOutput {
		display: flex;
		flex: 1;
		flex-direction: column;
	}
	.inputfield {
		margin-bottom: 10px;
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 4px;
		padding: 6px 12px;
	}
	.inputfieldvalue {
		margin-bottom: 10px;
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 4px;
		padding: 6px 12px;
	}
	.outputfield {
		border: 1px solid #ccc;
		border-radius: 4px;
		margin-bottom: 10px;
		width: 100%;
		padding: 6px 12px;
	}
}

.dilutionpage {
	width: 100%;
	height: 100%;
	padding: 0.5em 0;
	@include wide();
	@media print {
		padding: 0;
	}
	&--header {
		height: 2vh;
		display: flex;
		@media print {
			display: none;
		}
		&--title {
			color: white;
			background: $main-green;
			width: 160px;
			text-align: center;
		}
	}

	.faIcon {
		transition: transform 0.2s linear;
		color: #50b948;
		margin-right: 10px;
		margin-left: 10px;

		&.rotated {
			transform: rotate(180deg);
			transition: transform 0.2s linear;
		}
	}

	.unitconverterwrap {
		transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
		max-height: 0px;
		overflow: hidden;

		&.expanded {
			max-height: 100vh;
			transition: max-height 0.2s cubic-bezier(0, 0, 1, 0);
		}
	}
	.converterpage {
		@media print {
			display: none;
		}
		.unitconverterPage {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
		}
		.swapbuttoncontainer {
			display: flex;
			flex-direction: column;
			justify-content: start;
		}
		.swapButton,
		.equalicon {
			width: 30px;
			height: 30px;
			margin: calc(6px + 1.2em) 30px 0 30px;
			border: 1px;
			text-decoration: none;
			display: inline-block;
		}
		.equalicon {
			margin-top: 0;
			display: flex;
    		justify-content: center;
    		align-items: center;
		}
		.centeredText {
			text-align: center;
		}
		.unitconverterfield {
			display: flex;
			width: 75%;
			margin-bottom: 20px;
			justify-content: center;
		}

		.convertInput,
		.convertOutput {
			display: flex;
			flex: 1;
			flex-direction: column;
		}
		.inputfield,
		.inputfieldvalue {
			margin-bottom: 10px;
			width: 100%;
			border: 1px solid #ccc;
			border-radius: 4px;
			padding: 6px 12px;
		}
		.outputfield {
			border: 1px solid #ccc;
			border-radius: 4px;
			margin-bottom: 10px;
			width: 100%;
			padding: 6px 12px;
		}
	}
	&--content {
		height: calc(100% - 4vh);
		@media print {
			height: 100%;
			background: white;
		}
		.container {
			height: 100%;
			padding-top: 0.5em;
			display: flex;
			flex-wrap: wrap;
			@media print {
				padding: 0;
			}
			.values {
				flex: 1 1 40%;
				background: white;
				max-width: 500px;
				display: flex;
				flex-wrap: wrap;
				overflow: auto;
				position: relative;
				.watermark {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					&--image {
						display: none;
						height: 20%;
						@media (orientation: landscape) {
							height: 15%;
						}
						opacity: 0.15;
						background-image: url("../client/assets/logo.png");
						background-position: center;
						background-size: contain;
						background-repeat: repeat-x;
						&.offset {
							background-position-x: left;
						}
						@media print {
							width: 100%;
							display: block;
						}
					}
				}
				@media print {
					max-width: unset;
					justify-content: center;
				}
				> .logo {
					display: none;
					@media print {
						display: block;
						position: relative;
						width: 100%;
						padding-top: 10px;
						padding-left: 10px;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
					}
					> img {
						height: 50px;
					}
				}
				.item {
					position: relative;
					width: 100%;
					padding: 0.3em 0.5em;
					font-size: 1.5vh;
					@media (max-width: 960px) {
						width: 90%;
						margin: 0.5em !important;
						min-height: 152px;
					}
					@media print {
						background: rgba(255, 255, 255, 0.7);
						box-shadow: none;
						margin: 0 !important;
						width: 70%;
						min-height: unset;
					}
					form {
						margin: 0;
					}
					&--header {
						font-weight: 600;
						height: 4vh;
					}
					&--units {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						.unitwrap {
							width: 30%;
							> select {
								width: 100%;
								height: 3vh;
							}
						}
					}
					&--values {
						display: flex;
						justify-content: space-between;
					}
					@include rowspecs();
					.row {
						width: 49%;
					}
					.flex {
						display: flex;
						justify-content: space-between;
						@include rowspecs();
						.row {
							width: 49%;
							&.w50 {
								width: 48%;
							}
						}
					}
					.flexthree {
						display: flex;
						justify-content: space-between;
						@include rowspecs();
						.row {
							width: 15%;
						}
						.row:first-child {
							width: 59%;
						}
					}
					&.w50 {
						width: 50%;
						@media print {
							width: 70%;
						}
						.row {
							width: 100%;
						}
					}
				}
				#unitconvertervalues {

					position: relative;
					@media print {
						display: none;
					}
					input, select {
						height: 3vh;
						padding: 0;
						margin: 0;
					}
				}
				> .printfooter {
					display: none;
					@media print {
						position: relative;
						width: 100%;
						height: 3.5vh;
						display: flex;
						justify-content: flex-end;
						padding-right: 15px;
					}
					&--text {
						width: 200px;
					}
				}
				&--controls {
					position: relative;
					flex: 1;
					padding-right: 1em;
					display: flex;
					box-shadow: $shadow;
					justify-content: flex-end;
					background: white;
					font-weight: 600;
					color: $main-green;
					@media print {
						display: none;
					}
					> div {
						margin: 0 0.5em;
						cursor: pointer;
						height: 1em;
					}
				}
			}
			.image {
				height: 100%;
				display: flex;
				justify-content: center;
				flex: 1 0;
				@media print {
					display: none;
				}
				.imagewrapper {
					height: 100%;
					background: white;
					font-size: 2.2vh;
					padding: 0.2em 1em;
					&--title {
						height: 4vh;
					}
					&--desc {
						display: none;
						height: 4vh;
						&.link {
							color: $main-green;
							text-decoration: underline;
						}
					}
					&--area {
						height: calc(100% - 75px);
						> img {
							max-height: 100%;
						}
					}
				}
			}
		}
	}
}

.backbutton {
	cursor: pointer;
	width: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	&--icon {
		height: 16px;
		width: 16px;
		background-image: url("../client/assets/chevron-back.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}

@mixin docpage {
	.topbar {
		height: 4vh;
		width: 100%;
		display: flex;
		&--back {
			cursor: pointer;
			width: 3em;
			display: flex;
			justify-content: center;
			align-items: center;
			&--icon {
				height: 16px;
				width: 16px;
				background-image: url("../client/assets/chevron-back.svg");
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}
		}
		&--title {
			font-size: 20px;
			display: flex;
			align-items: center;
		}
	}
	.docs {
		height: calc(100% - 4vh);
		width: 100%;
		overflow-y: scroll;
		.row {
			width: 100%;
			display: flex;
			.doc {
				padding: 1em;
				width: 25%;
				height: calc(100px + 2em);
				.item {

					background: white;
					height: 100px;
					padding: 12px;
					width: 100%;
					display: flex;
					cursor: pointer;
					&--thumb {
						height: 100%;
						width: 76px; // parent height - 2x parent padding, to get square.
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
					}
					&--wrap {
						padding: 0 0 0 1em;
						width: calc(100% - 76px);
						&--title {
							height: calc(100% - 2px);
							overflow: hidden;
						}
						&--bottom {
							border-bottom: 2px solid $main-green;
						}
					}
				}
			}
		}
		.nodocs {
			margin: 2em;
		}
	}
}

#materialslayout {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 100%;
	@media (max-width: 960px) {
		margin-bottom: 1em;
		height: unset;
	}
	@include docpage();
}

#tutorialslayout {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 100%;
	@media (max-width: 960px) {
		margin-bottom: 1em;
		height: unset;
	}
	@include docpage();
}
